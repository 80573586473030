import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import ZipcodePage from 'src/px/ZipcodePage';
import { Page } from '../../components';
import { $t, shouldHandoffToFreelancersUnion } from 'src/utils';
import SessionContext from 'src/context/SessionContext';
import { goTo } from 'src/utils/links';
import { Partner } from 'src/types';
import { County } from 'src/hooks/useZipcode';
import paths from 'src/constants/paths';

export const ExplorerZipcodeView = ({}) => {
  const { partner } = useContext(SessionContext);

  const handleNext = (place: County) => {
    // checks whether we have any special zip or partner handling
    if (partner === Partner.Decisely) {
      navigate(paths.EXPLORER_PLANS);
    } else if (shouldHandoffToFreelancersUnion({ fips: place?.fips, partner })) {
      goTo('https://freelancersunion.org/insurance/health/ny');
    } else {
      navigate(paths.EXPLORER_INCOME);
    }
  };

  return (
    <Page
      hideBanner
      context="Explorer"
      progress={0.25}
      metadata={{
        title: $t({ id: 'explorer/zip.meta-title', defaultMessage: 'Explore Health Plans' }),
        description: $t({
          id: 'explorer/zip.meta-description',
          defaultMessage: 'Get covered for 2023',
        }),
        image: '/img/og/explorer.png',
      }}
    >
      <ZipcodePage flow="Explorer" onNext={handleNext} />
    </Page>
  );
};

export default ExplorerZipcodeView;
